html {
    height: 100%;
    font-family: montserrat;
}

body {
    height: 100vh;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: linear-gradient(135deg, #4556A7 0%, #2F3C76 100%);
}

div.fullscreen {
    height: 100%;
}

#root {
    height: 100%;
}
